// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/karolis/zero/monorepo/zero-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/Users/karolis/zero/monorepo/zero-website/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/karolis/zero/monorepo/zero-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-catapult-ecommerce-js": () => import("/Users/karolis/zero/monorepo/zero-website/src/pages/work/catapult-ecommerce.js" /* webpackChunkName: "component---src-pages-work-catapult-ecommerce-js" */),
  "component---src-pages-work-manatee-app-js": () => import("/Users/karolis/zero/monorepo/zero-website/src/pages/work/manatee-app.js" /* webpackChunkName: "component---src-pages-work-manatee-app-js" */),
  "component---src-pages-work-ucsd-cmu-sleep-js": () => import("/Users/karolis/zero/monorepo/zero-website/src/pages/work/ucsd-cmu-sleep.js" /* webpackChunkName: "component---src-pages-work-ucsd-cmu-sleep-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/karolis/zero/monorepo/zero-website/.cache/data.json")


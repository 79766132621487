module.exports = [{
      plugin: require('/Users/karolis/zero/monorepo/zero-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-122682829-1","respectDNT":true},
    },{
      plugin: require('/Users/karolis/zero/monorepo/zero-website/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://73469b84e2a84e3aaa0c41cd435d84dd@sentry.io/1291874"},
    },{
      plugin: require('/Users/karolis/zero/monorepo/zero-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
